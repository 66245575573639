// eslint-disable-next-line no-unused-vars
const moduleName = "encryption";

const CryptoJS = require("crypto-js");

function initialState() {
  return {
    genericKey: "39c328df0a75370261f4687e55e0cb39", // gotta be 32 chars
    goodChars:
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()-_=+/?.>,<;:'[]{}",
    scrambleChars:
      "5<idQIP0b#A_V$XNE):6KjWtLwv&4^k-+xo}G8ur%aO=/{M2p;J*Zl9q,e7'Rz]FB.[y(UH?CSgcsn@!f3DY>T1hm",
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {},
  actions: {
    resetStore() {
      // doesn't need to do anything, stub for compatibility
    },
  },
  getters: {
    encrypt: (state) => (message, key) => {
      key = key ? key : state.key;
      if (key.length == 32) {
        key = CryptoJS.enc.Utf8.parse(key);
      }
      let iv = CryptoJS.enc.Utf8.parse(
        CryptoJS.lib.WordArray.random(8).toString()
      );
      let encrypted = CryptoJS.AES.encrypt(message, key, { iv });
      return (
        iv.toString(CryptoJS.enc.Utf8) +
        encrypted.ciphertext.toString(CryptoJS.enc.Hex)
      );
    },
    decrypt: (state) => (message, key) => {
      key = key ? key : state.key;
      if (key.length == 32) {
        key = CryptoJS.enc.Utf8.parse(key);
      }
      let iv = CryptoJS.enc.Utf8.parse(message.substring(0, 16));
      let outStr = CryptoJS.AES.decrypt(
        CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Hex.parse(message.substring(16)),
          iv,
          key,
        }),
        key,
        { iv }
      ).toString(CryptoJS.enc.Utf8);
      if (outStr[0] == "{" || outStr[0] == "[") {
        try {
          return JSON.parse(outStr);
        } catch (err) {
          // don't care
        }
      }
      return outStr;
    },
    unscramble: () => (message, count) => {
      // message = decodeURIComponent(message);
      count = count ? count : 32;
      let tick = true;
      let out1 = "";
      let out2 = "";
      let len = message.length;
      for (let i = 0; i < len; i++) {
        if (tick && count) {
          out1 += message[i];
          count--;
        } else {
          out2 += message[i];
        }
        tick = !tick;
      }
      return [out1, out2];
    },
    replace: (state) => (message) => {
      let out = "";
      for (let i = 0; i < message.length; i++) {
        out += state.scrambleChars[state.goodChars.indexOf(message[i])];
      }
      return out;
    },
    unreplace: (state) => (message) => {
      let out = "";
      for (let i = 0; i < message.length; i++) {
        out += state.goodChars[state.scrambleChars.indexOf(message[i])];
      }
      return out;
    },
  },
};
